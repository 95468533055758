import React from "react";

const ExploreHome = () => {
  return (
    <div className="pt-16 md:px-[6%] px-[5%] ">
      <div className="w-full h-screen	 flex-center-center">
        <h1 className=" text-5xl ">COOMING SOON</h1>
      </div>
    </div>
  );
};

export default ExploreHome;
